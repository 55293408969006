import ConfirmModal from '@/components-v2/molecules/ConfirmModal'
import { ModalSettings } from '@mantine/modals/lib/context'

export const modalDefaultProps: ModalSettings = {
  centered: true,
  withCloseButton: true,
  radius: 'md',
  closeButtonProps: { size: 'lg' },
  styles: {
    header: {
      padding: '24px 24px 0px 24px',
      alignItems: 'flex-start'
    },
    title: {
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: 700,
      flex: 1
    },
    body: {
      padding: '0px 24px 24px 24px'
    }
  }
}

const contextModals = {
  confirm: ConfirmModal

  /* other modals */
}

declare module '@mantine/modals' {
  export interface MantineModalsOverride {
    modals: typeof contextModals
  }
}

export default contextModals
