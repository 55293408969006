import { useCallback, useEffect, useState } from 'react'

import { clearUserStates } from '../utils/clearUserStates'
import { useAppDispatch, useAppSelector } from '@/state'
import { ConnectingWalletEnum, userSelectors } from '@/state/user/reducer'
import { connectingWallet as connectingWalletAction } from '@/state/user/actions'
import { useRouter } from 'next/router'
import { removeAccessToken } from '@/utils/localStorageService'
import { api } from '@/api'
import { setAccessToken, setUserInfo } from '@/slice/authentication/auththentication.slice'
import { TXN_COLUMNS_STORAGE_KEY, TXN_FILTERS_STORAGE_KEY } from '@/views/Transactions/interface'
import { clearOrgList } from '@/slice/account/account-slice'
import { useAccount, useDisconnect } from 'wagmi'

const useAuth = () => {
  const { address: account } = useAccount()
  const { disconnect } = useDisconnect()
  const [logining, setLogining] = useState(false)
  const dispatch = useAppDispatch()
  const router = useRouter()
  const connectingWallet = useAppSelector(userSelectors.connectingWalletSelector)
  const [redirectAuthenticate, setRedirectAuthenticate] = useState(false)
  const [status, setStatus] = useState(false)
  const [message, setMessage] = useState('')

  useEffect(() => {
    if (!account && connectingWallet === ConnectingWalletEnum.Pending) {
      dispatch(connectingWalletAction(ConnectingWalletEnum.Done))
      setRedirectAuthenticate(true)
      setMessage('')
    }
  }, [connectingWallet, dispatch, account])

  const login = useCallback(
    async (connectorID: any, userAction = false) => {
      console.log('CONNECTOR ID: ', connectorID)
      // window.localStorage.setItem(connectorLocalStorageKey, connectorID)
      if (userAction) {
        dispatch(connectingWalletAction(ConnectingWalletEnum.Pending))
      }
      setLogining(true)
      // const connector = connectorsByName[connectorID]
      const connector = null
      if (connector) {
        // Support another network for Multisend app

        if (connectorID === 'injected') {
          await (connector as any).isAuthorized()
        }
      } else {
        setLogining(false)
      }
    },
    [dispatch]
  )
  const logout = useCallback(() => {
    disconnect()
    clearUserStates(dispatch)
    removeAccessToken()
    dispatch(api.util.resetApiState())
  }, [disconnect, dispatch])

  const logoutRedirect = useCallback(() => {
    disconnect()
    clearUserStates(dispatch)
    removeAccessToken()
    dispatch(api.util.resetApiState())
    dispatch(
      setUserInfo({
        firstName: '',
        lastName: '',
        email: ''
      })
    )
    dispatch(setAccessToken(null))
    dispatch(clearOrgList())

    window.sessionStorage.removeItem('do-not-show-security-reminder-modals')
    window.sessionStorage.removeItem('show_banner')
    window.sessionStorage.removeItem('show_banner_monetisation')
    window.sessionStorage.removeItem('wallets_synced')
    window.sessionStorage.removeItem('dismiss-card-mission-control')
    window.sessionStorage.removeItem(TXN_FILTERS_STORAGE_KEY)
    window.sessionStorage.removeItem(TXN_COLUMNS_STORAGE_KEY)
    router.push('/')
  }, [disconnect, dispatch])

  return { login, logout, redirectAuthenticate, logining, setStatus, status, message, setLogining, logoutRedirect }
}

export default useAuth
