import React from 'react'
import { BaseModal } from '../Modals/BaseModal'
import WarningIcon from '@/public/svg/icons/orange-round-warning.svg'
import SignatureIntro from '@/public/image/SignatureIntro.png'
import CheckIcon from '@/public/svg/icons/check-icon.svg'
import { Button, Flex, Text, Title } from '@mantine/core'
import Image from 'next/image'
import { useOrganizationId } from '@/utils/getOrganizationId'


interface ISecurityWarningBanner {
  provider: any
}
const SecurityWarningBanner: React.FC<ISecurityWarningBanner> = ({ provider }) => {
  const organisationId = useOrganizationId()
  const handleOnClick = () => {
    provider.methods.setIsOpen(false)
    const currentSessionStorageItem = JSON.parse(window.sessionStorage.getItem('do-not-show-security-reminder-modals'))
    if (currentSessionStorageItem) {
      window.sessionStorage.setItem(
        'do-not-show-security-reminder-modals',
        JSON.stringify([...currentSessionStorageItem, organisationId])
      )
    } else {
      window.sessionStorage.setItem('do-not-show-security-reminder-modals', JSON.stringify([organisationId]))
    }
  }
  return (
    <BaseModal provider={provider} width="700" classNames="rounded-3xl w-[700px]">
      <BaseModal.Body extendedClass="!p-0 !m-0 relative">
        <Flex>
          <Flex direction="column" className="w-[380px] p-8 gap-8">
            <Flex className="gap-4 " justify="start" align="start">
              <Image src={WarningIcon} width={48} height={48} alt="warning_icon" className="pt-1" />
              <Title size={24}>Important Security Reminder!</Title>
            </Flex>
            <Text>
              Due to recent security incidents, please take extra caution when approving transactions.
              <Text span fw={700}>
                {' '}
                Once signed, transactions are irreversible.
              </Text>
            </Text>
            <Flex direction="column" className=" gap-4">
              <div className="flex items-start gap-3">
                <div className="p-1 rounded-full bg-[#FDF455] flex items-center">
                  <Image src={CheckIcon} width={12} height={12} alt="check-icon" />
                </div>
                <Text size="md">
                  <span className="font-bold">ALWAYS VERIFY </span>
                  transaction details before signing.
                </Text>
              </div>
              <div className="flex items-start gap-3">
                <div className="p-1 rounded-full bg-[#FDF455] flex items-center">
                  <Image src={CheckIcon} width={12} height={12} alt="check-icon" />
                </div>
                <Text size="md">If you can’t verify it, don’t sign it.</Text>
              </div>
              <div className="flex items-start gap-3">
                <div className="p-1 rounded-full bg-[#FDF455] flex items-center">
                  <Image src={CheckIcon} width={12} height={12} alt="check-icon" />
                </div>
                <Text size="md">Use a secure device and trusted network.</Text>
              </div>
            </Flex>
            <Text size="md">Stay safe. Learn more security tips here.</Text>
            <Button onClick={handleOnClick} size="48">
              I Understand
            </Button>
          </Flex>
          <div className="bg-grey-200 p-[10px] flex flex-col justify-center items-center flex-1 rounded-r-3xl">
            <Image src={SignatureIntro} width={250} height={292} unoptimized alt="signature" />
          </div>
        </Flex>
      </BaseModal.Body>
    </BaseModal>
  )
}

export default SecurityWarningBanner
