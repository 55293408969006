import WarningIcon from '@/public/svg/icons/round-warning.svg'
import { Box, BoxComponentProps, Button, ButtonProps, Flex, FlexProps } from '@mantine/core'
import { ContextModalProps } from '@mantine/modals'
import Image from 'next/image'
import React, { useEffect } from 'react'

interface ConfirmModalProps {
  modalBody?: React.ReactNode
  titleIcon?: any
  withIcon?: boolean
  onConfirm?: (e?: React.MouseEvent<HTMLButtonElement>) => Promise<void> | void
  onCancel?: (e?: React.MouseEvent<HTMLButtonElement>) => void
  labels?: { confirm?: string; cancel?: string }
  modalBodyProps?: BoxComponentProps
  cancelBtnProps?: ButtonProps & React.ComponentPropsWithoutRef<'button'>
  confirmBtnProps?: ButtonProps & React.ComponentPropsWithoutRef<'button'>
  btnGroupProps?: FlexProps
  modalTitleProps?: FlexProps
}

const ConfirmModal = ({
  context,
  id,
  innerProps: {
    modalBody,
    onConfirm,
    onCancel,
    modalBodyProps,
    cancelBtnProps,
    confirmBtnProps,
    btnGroupProps,
    modalTitleProps,
    labels,
    withIcon = true,
    titleIcon = WarningIcon
  }
}: ContextModalProps<ConfirmModalProps>) => {
  const [isLoading, setIsLoading] = React.useState(false)

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    onCancel?.(e)
    context.closeModal(id)
  }

  const handleConfirm = async (e: React.MouseEvent<HTMLButtonElement>) => {
    try {
      setIsLoading(true)
      await onConfirm?.(e)
      context.closeModal(id)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (titleIcon && withIcon && id) {
      const currentModal = context.modals.find((modal) => modal.id === id)

      if (currentModal)
        context.updateContextModal({
          modalId: id,
          title: (
            <Flex gap={16} wrap="nowrap" align="flex-start" {...modalTitleProps}>
              <Image src={titleIcon} width={50} height={50} alt="confirm-modal-icon" />
              {currentModal.props.title}
            </Flex>
          )
        })
    }
  }, [titleIcon, id])

  return (
    <>
      {modalBody && (
        <Box pl={titleIcon && withIcon ? 68 : 0} c="gray.7" {...modalBodyProps}>
          {modalBody}
        </Box>
      )}

      <Flex gap={12} mt={modalBody ? 32 : 0} {...btnGroupProps}>
        <Button
          size="lg"
          color="secondary"
          fw={600}
          fz={16}
          {...cancelBtnProps}
          disabled={isLoading}
          onClick={handleCancel}
        >
          {labels?.cancel || 'Cancel'}
        </Button>
        <Button
          size="lg"
          flex={!btnGroupProps?.direction?.toString()?.includes('column') && 1}
          fw={600}
          fz={16}
          {...confirmBtnProps}
          loading={isLoading}
          onClick={handleConfirm}
        >
          {labels?.confirm || 'Confirm'}
        </Button>
      </Flex>
    </>
  )
}

export default ConfirmModal
